import { createSlice } from "@reduxjs/toolkit";
// Built with 💖 by Hridayesh
// https://hridayeshpadalkar.in/
const initialState = {
    videos: [],
    totalVideos: 0,
    hasMore: true
};

const videoSlice = createSlice({
    name: "videos",
    initialState,
    reducers: {
        setVideos: (state, { payload }) => {
            console.log("Payload", payload);
            state.videos = payload.newVideo || [];
            state.totalVideos = payload?.totalVideos || 0;
            state.hasMore = payload?.hasMoreBlog;
        },
        setNextVideos: (state, { payload }) => {
            console.log("Response", payload);

            state.videos = state.videos.concat(payload.newVideo || []);
            state.hasMore = payload.hasMoreBlog;
            if (payload.totalVideos) {
                state.totalVideos = payload.totalVideos;
            }
        },
        setHasMore: (state, { payload }) => {
            state.hasMore = payload.doesHaveMore;
        },
        setLikeVideo: (state, { payload }) => {
            // Find the project by _id and toggle its isFav property
            console.log(payload.contentId);
            const videoIndex = state.videos.findIndex(
                (video) => video._id === payload.contentId
            );
            console.log(videoIndex, "PRI");
            console.log(state.videos[videoIndex], state.videos);
            if (videoIndex !== -1) {
                // Toggle the isFav value
                state.videos[videoIndex].isFav = !state.videos[videoIndex].isFav;
                if (state.videos[videoIndex].isFav) {
                    state.videos[videoIndex].totalFavorites += 1;
                }
                else {
                    state.videos[videoIndex].totalFavorites -= 1;
                }
            }
        }
    },

});

export const { setVideos, setNextVideos, setHasMore, setLikeVideo } = videoSlice.actions;

export default videoSlice.reducer;

