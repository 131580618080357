import React, { useState, useEffect, useRef } from "react";
import { assetsUrl } from "../../config/url";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { resetFilters, setFilters } from "../../store/features/filterSlice";
import { getFavCount } from "../../services/projectService";
import { fetchFilterProps, searchProps } from "../../services/filterService";
import {
	setHittingFalse,
	setHittingTrue,
} from "../../store/features/searchSlice";

// Built with 💖 by Hridayesh
// https://hridayeshpadalkar.in/

const NavBar = () => {
	const dispatch = useDispatch();
	const projects = useSelector((state) => state.projects.projects);
	const blogs = useSelector((state) => state.blogs?.blogs || []);
	const videos = useSelector((state) => state.videos?.videos || []);
	const location = useLocation();
	const navigate = useNavigate();
	console.log("Current Path", location);
	const [areaList, setAreasList] = useState([]);
	const [nearByPlaceList, setNearByPlaceList] = useState([]);
	const [titleList, setTitleList] = useState([]);
	const [searchTags, setSearchTags] = useState([]);
	const finalFilters = {
		type: null,
		config: null,
		area: null,
		possessionStatus: null,
		minPrice: 100000,
		maxPrice: 25000000,
		nearByPlaces: null,
		title: null,
		anonymous: null
	};
	const [favoriteCount, setFavoriteCount] = useState(null);
	const [isUpdated, setIsUpdated] = useState(false);
	const filters = useSelector((state) => state.filters);
	const [menuOpen, setMenuOpen] = useState(false);
	const [searchOpen, setSearchOpen] = useState(false);
	const [searchText, setSearchText] = useState("");
	const menuRef = useRef(null);
	const searchRef = useRef(null);

	const getFav = async () => {
		setIsUpdated(true);
		const resp = await getFavCount();
		if (resp?.data?.count) {
			setFavoriteCount(resp?.data?.count);
		} else {
			setFavoriteCount(null);
		}
		setTimeout(() => {
			setIsUpdated(false);
		}, 2000);
	};

	const reset = () => {
		dispatch(setHittingFalse());
		dispatch(resetFilters());
		setSearchText("");
		setSearchOpen(false);
	};

	const [filterDataFetched, setFilterDataFetched] = useState(false);

	const getFilterData = async () => {
		if (
			!filterDataFetched &&
			areaList.length === 0 &&
			nearByPlaceList.length === 0 &&
			titleList.length === 0
		) {
			setFilterDataFetched(true);
			try {
				const resp = await fetchFilterProps();
				setTitleList(resp.data.title || []);
				setNearByPlaceList(resp.data.nearbyPlaces || []);
				setAreasList(resp.data.areas || []);
				console.log("Search Tags",resp.data.searchKeyWords[0].uniqueKeywords);
				setSearchTags(resp.data.searchKeyWords[0].uniqueKeywords || []);
			} catch (error) {
				console.error("Error fetching filter data:", error);
			}
		}
	};

	// useEffect(() => {
	// 	const handleClickOutside = (event) => {
	// 		if (menuRef.current && !menuRef.current.contains(event.target)) {
	// 			setMenuOpen(false);
	// 		}
	// 		if (searchRef.current && !searchRef.current.contains(event.target)) {
	// 		  reset();
	// 		}
	// 	};

	// 	document.addEventListener("mousedown", handleClickOutside);
	// 	return () => {
	// 		document.removeEventListener("mousedown", handleClickOutside);
	// 	};
	// }, []);

	const toggleMenu = () => {
		setMenuOpen((prev) => !prev);
	};

	const toggleSearch = () => {
		setSearchOpen((prev) => !prev);
		if (!searchOpen) {
			setSearchText(""); // Clear search text when opening
		}
		
			navigate('/');
		
	};

	const handleSearch = () => {
		// Detect keywords and set corresponding filters
		let filters = {
			type: null,
			config: null,
			area: null,
			possessionStatus: null,
			minPrice: 100000,
			maxPrice: 25000000,
			nearByPlaces: null,
			title: null,
			anonymous: null
		};
		
		titleList?.forEach((title) => {
			if (title.toLowerCase().includes(searchText.toLowerCase())) {
				filters.title = title;
			}
			if (searchText.toLowerCase().includes(title.toLowerCase())) {
				filters.title = title;
			}
		});
		filters.anonymous = searchText.toLowerCase();
		console.log("Filters", filters, finalFilters);
		// Update the filters state dynamically based on detected keywords
		dispatch(setFilters(filters));
		const areObjectsEqual = (obj1, obj2) => {
			const keys1 = Object.keys(obj1);
			const keys2 = Object.keys(obj2);

			// Check if both objects have the same number of keys
			if (keys1.length !== keys2.length) {
				return false;
			}

			// Check if all keys and their values are the same in both objects
			for (let key of keys1) {
				if (obj1[key] !== obj2[key]) {
					return false;
				}
			}

			return true;
		};

		if (areObjectsEqual(finalFilters, filters)) {
			console.log("Hitting for search");
			dispatch(setHittingTrue());
		}
	};

	const handleKeyDown = async(e) => {
		dispatch(setHittingFalse());
		// Check if Enter key (keyCode 13) is pressed
		if (e.key === "Enter") {
			handleSearch();
			const data = {text: searchText};
			console.log("RER", data);
			const resp = await searchProps(data);
			console.log("rprprprprp", resp);
		}
	};

	const handleLinkClick = () => {
		setMenuOpen(false);
	};

	useEffect(() => {
		getFav();
	}, [projects, blogs, videos]);

	useEffect(() => {
		getFilterData();
	}, []);
	return (
		<div className='bg-nav-500 text-base-100 flex flex-row justify-between w-full px-4 py-3 sm:px-12 sm:py-5 relative'>
			<Link to='/'>
				<img
					src={`${assetsUrl}/assets/svgs/flats_in_kop_mobile_icon.svg`}
					className='w-full h-full block sm:hidden'
					alt='Flats In Kolhapur Logo'
					fetchpriority='high'
				/>
				<img
					src={`${assetsUrl}/assets/svgs/flats_in_kop_desktop_icon.svg`}
					className='w-full h-full hidden sm:block'
					alt='Flats In Kolhapur Logo'
					fetchpriority='high'
				/>
			</Link>
			{/* Search Bar */}
			{searchOpen && (
				<input
					// ref={searchRef}
					type='text'
					className='bg-base-100 text-base-600 ml-10 my-1 rounded-l-md px-2 border-l-2 border-t-2 border-b-2 border-r-0 border-base-100 flex-1 focus:outline-none hidden sm:block'
					placeholder='Press enter to search...'
					value={searchText}
					autoFocus
					onChange={(e) => setSearchText(e.target.value)}
					onKeyDown={handleKeyDown}
				/>
			)}
			<div className='flex items-center gap-4'>
				{searchOpen && (
					<div className='cursor-pointer'>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							fill='none'
							viewBox='0 0 24 24'
							strokeWidth={1.5}
							stroke='currentColor'
							className={
								"w-9 h-14 cursor-pointer hidden sm:block rounded-r-md border-r-2 border-t-2 border-b-2 border-base-100 bg-base-100 text-nav-500"
							}
							onClick={() => {
								reset();
							}}>
							<path
								strokeLinecap='round'
								strokeLinejoin='round'
								d='M6 18 18 6M6 6l12 12'
							/>
						</svg>
					</div>
				)}
				{!searchOpen && (
					<img
						onClick={() => {
							toggleSearch();
						}}
						src={`${assetsUrl}/assets/svgs/navbar_icons/search.svg`}
						className={`w-9 h-full cursor-pointer hidden sm:block ${
							searchOpen && "hidden"
						}`}
						alt='Search'
					/>
				)}
				<Link to='/favorite' className='relative'>
					<img
						src={`${assetsUrl}/assets/svgs/navbar_icons/favorite.svg`}
						className='w-8 h-full hidden sm:block'
						alt='Favorite'
					/>

					{favoriteCount && (
						<div
							className={`hidden sm:block absolute -bottom-0 -right-1 bg-nav-500 border-2 px-[3px] border-base-100 rounded-full text-xs text-base-100 font-bold`} // scale animation
						>
							{favoriteCount}
						</div>
					)}
				</Link>
				<Link to='/my-account'>
					<img
						src={`${assetsUrl}/assets/svgs/navbar_icons/account.svg`}
						className='w-7 h-full hidden sm:block'
						alt='Account'
					/>
				</Link>

				<img
					onClick={toggleMenu}
					src={`${assetsUrl}/assets/svgs/navbar_icons/hamburger.svg`}
					className='w-10 sm:w-11 h-full cursor-pointer'
					alt='Hamburger'
					fetchpriority='high'
				/>
			</div>
			{menuOpen && (
				<div
					ref={menuRef}
					className='absolute z-50 flex flex-col w-24 bg-base-300 text-base-600 opacity-95 top-full right-10 sm:right-16 shadow-nav-hamburger-menu-shadow'>
					<Link
						to='/about'
						className={
							location.pathname.split("/")[1] === "about"
								? "w-full flex items-center justify-center py-1 shadow-nav-hamburger-menu-link-shadow"
								: "w-full flex items-center justify-center py-1 hover:shadow-nav-hamburger-menu-link-shadow"
						}
						onClick={handleLinkClick}>
						About Us
					</Link>
					<Link
						to='/blogs'
						className={
							location.pathname.split("/")[1] === "favorite"
								? "w-full flex items-center justify-center py-1 shadow-nav-hamburger-menu-link-shadow"
								: "w-full flex items-center justify-center py-1 hover:shadow-nav-hamburger-menu-link-shadow"
						}
						onClick={handleLinkClick}>
						Blogs
					</Link>
					<Link
						to='/videos'
						className={
							location.pathname.split("/")[1] === "favorite"
								? "w-full flex items-center justify-center py-1 shadow-nav-hamburger-menu-link-shadow"
								: "w-full flex items-center justify-center py-1 hover:shadow-nav-hamburger-menu-link-shadow"
						}
						onClick={handleLinkClick}>
						Videos
					</Link>
					<Link
						to='/favorite'
						className={
							location.pathname.split("/")[1] === "favorite"
								? "w-full flex items-center justify-center py-1 shadow-nav-hamburger-menu-link-shadow"
								: "w-full flex items-center justify-center py-1 hover:shadow-nav-hamburger-menu-link-shadow"
						}
						onClick={handleLinkClick}>
						Favorites
					</Link>
				</div>
			)}
		</div>
	);
};

export default NavBar;
